<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <PreviewEditableDropdown
    v-else
    v-model:open="open"
    v-bind="$props"
    @change="(...args: [string, string?]) => $emit('change', ...args)"
    @update-open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import type { FieldCallback, FieldOption } from '#field/types'

const props = defineProps<{
  value: string | undefined
  description?: string
  label?: string
  options: string
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  cellClass?: string
  disableSetting?: boolean
}>()

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'update:open', value: boolean): void
}>()

const open = ref(false)

const menuOptions = computed<Array<FieldOption>>(() => {
  return (safeParseStringToJSON(props.options, []) as FieldOption[]).filter(
    (option) => option.label
  )
})

const selectedOption = computed(() => {
  return menuOptions.value.find((option) => option.value === props.value)
})

const previewTitle = computed(() => {
  return selectedOption.value?.label ? selectedOption.value.label : '-'
})

defineExpose({
  focus: () => {
    open.value = true
  },
})

onMounted(() => {
  if (!props.preview) {
    emit('set-callback', {
      open: () => {
        open.value = true
      },
    })
  }
})
</script>
