<template>
  <div class="flex items-center justify-between pt-3 pb-2 px-4">
    <p class="font-semibold text-sm text-gray-900">{{ label }}</p>
    <UButton
      size="xs"
      icon="i-heroicons-x-mark"
      color="gray"
      variant="ghost"
      @click.prevent.stop="$emit('close')"
    />
  </div>
  <div class="px-4">
    <UInput
      v-model="search"
      icon="i-heroicons-magnifying-glass"
      size="sm"
      autofocus
      placeholder="Search option"
      :ui="{
        base: '!rounded-md',
      }"
    />
  </div>
  <div
    class="max-h-[21.375rem] overflow-y-auto scroll-stable minimal-scrollbar mt-2 pl-2 pr-0.5 pb-2 space-y-0.5"
  >
    <div
      v-for="(option, index) in filteredMenuOptions"
      ref="optionRefs"
      :key="option.value"
      :class="[
        'flex items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer rounded-md gap-1.5 hover:bg-gray-100',
        {
          'bg-gray-100':
            option.value === selected || currentFocusOption === index,
        },
      ]"
      :data-test="`drop-option-${option.label}`"
      @mouseenter="currentFocusOption = index"
      @mouseleave="currentFocusOption = -1"
      @click="onSelect(option.value)"
    >
      <Tooltip :text="option.label" arrow-class="!top-[unset]">
        <template #default="{ getTextRef }">
          <div
            :class="[
              'px-2 py-1 rounded-md',
              { '!cursor-not-allowed': disabled },
            ]"
            :style="getFieldOptionPreset(option.color)"
          >
            <span
              :ref="getTextRef"
              class="text-sm line-clamp-1 break-all font-medium"
            >
              {{ option.label }}
            </span>
          </div>
        </template>
      </Tooltip>
      <Icon
        v-if="option.value === selected"
        name="heroicons:check"
        :size="20"
        class="min-w-5"
      />
    </div>
    <div v-if="!disableSetting && can('dashboard.data.manage_tasks_sections')" class="pt-2 space-y-2">
      <UButton
        class="w-full flex justify-center"
        variant="soft"
        color="gray"
        @click="setSettingTab('tasks')"
      >
        Manage options
      </UButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { FieldOption } from '#field/types'

const props = defineProps({
  selected: {
    type: String
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fieldId: {
    type: String
  },
  menuOptions: {
    type: Array as PropType<FieldOption[]>,
    required: true,
  },
  disableSetting: {
    type: Boolean
  },
  label: {
    type: String
  },
  value: {
    type: String
  }
})

const emit = defineEmits(['close', 'change'])

const { can } = useBoardAbility()
const { setSettingTab } = useBoardSettingsNavigator()
const currentFocusOption = ref(-1)
const search = ref('')
const optionRefs = ref<HTMLElement[]>([])
const popoverRef = inject('popoverRef') as Ref<{ ref: () => { open: boolean } }>
const open = ref(popoverRef.value?.ref().open)

const filteredMenuOptions = computed(() => {
  return props.menuOptions.filter((option) => {
    return option.label.toLowerCase().includes(search.value.toLowerCase())
  })
})

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

const onSelect = (value: string) => {
  if (value != props.value) {
    emit('change', value, props.fieldId)
    emit('close')
  }
}

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value > 0) {
        currentFocusOption.value--
      } else {
        currentFocusOption.value = filteredMenuOptions.value.length - 1
      }

      scrollToView(currentFocusOption.value)
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value < filteredMenuOptions.value.length - 1) {
        currentFocusOption.value++
      } else {
        currentFocusOption.value = 0
      }

      scrollToView(currentFocusOption.value)
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (
        currentFocusOption.value >= 0 &&
        filteredMenuOptions.value[currentFocusOption.value]
      ) {
        onSelect(filteredMenuOptions.value[currentFocusOption.value].value)
      }
    },
  },
})
</script>
